import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ChoseMap from './choosemap'; // Asegúrate de importar el componente correctamente

const CompShowInicio = () => {
  const [selectedSide, setSelectedSide] = useState(null);

  const handleSideSelect = (side) => {
    setSelectedSide(side);
  };

  const handleBack = () => {
    setSelectedSide(null);
  };

  return (
    <>
      <header className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-orange-500">SMOKESCS2</h1>
          <nav>
            <a href="#about" className="text-white mr-4">About</a>
            <a href="#contact" className="text-white">Contact</a>
          </nav>
        </div>
      </header>

      <main className="relative w-full h-screen">
        <img src="./1.jpeg" alt="Counter Strike 2" className="absolute inset-0 w-full h-full object-cover" />
        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-70"></div>

        <AnimatePresence>
          {!selectedSide && (
            <motion.div 
              className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-70"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-4xl font-bold text-white mb-4">Choose your side</h2>
              <div className="flex space-x-4">
                <button 
                  onClick={() => handleSideSelect('counter')}
                  className="py-2 px-4 rounded bg-blue-500 hover:bg-blue-600 text-white">
                  Counter-Terrorists
                </button>
                <button 
                  onClick={() => handleSideSelect('terrorist')}
                  className="py-2 px-4 rounded bg-orange-500 hover:bg-orange-600 text-white">
                  Terrorists
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {selectedSide && (
            <motion.div
              className={`absolute inset-0 flex flex-col items-center justify-center bg-${selectedSide === 'counter' ? 'blue-500' : 'orange-500'} bg-opacity-90`}
              initial={{ x: selectedSide === 'counter' ? '-100%' : '100%' }}
              animate={{ x: 0 }}
              exit={{ x: selectedSide === 'counter' ? '100%' : '-100%' }}
              transition={{ duration: 0.5 }}
            >
              
              <ChoseMap onBack={handleBack} side={selectedSide} />
            </motion.div>
          )}
        </AnimatePresence>
      </main>

      <footer className="bg-gray-800 p-4">
        <div className="container mx-auto text-center text-white">
          <p>&copy; 2024 SMOKESCS2. All rights reserved.</p>
          <p>Not affiliated with Valve Corporation.</p>
        </div>
      </footer>
    </>
  );
};

export default CompShowInicio;
