import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';
import { useLocation, Link } from 'react-router-dom';


const SmokeMap = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const map = queryParams.get('map') || 'Unknown Map'; // Fallback if map is not provided
    const side = queryParams.get('side') || 'Unknown Side'; // Fallback if side is not provided
    const [smokeData, setSmokeData] = useState([]);


    useEffect(() => {
        fetch('smokeData.json')
            .then(response => response.json())
            .then(data => setSmokeData(data))
            .catch(error => console.error('Error loading smoke data:', error));
    }, []);

    console.log(smokeData);
    const [selectedArea, setSelectedArea] = useState('');
    const [showStepByStep, setShowStepByStep] = useState(false);

    const handleAreaSelect = (area) => {
        setSelectedArea(area);
        setShowStepByStep(false); // Reset step-by-step view when selecting new area
        const scrollTarget = document.body.scrollHeight + 400; // Adjust the increment as needed

        if (window.innerWidth <= 768) { // Adjust this breakpoint as needed
            window.scrollTo({
                top: scrollTarget,
                behavior: 'smooth',
            });
        }
    };

    const availableSmokes = smokeData.filter(smoke => smoke.map === map);


    const toggleStepByStep = () => {
        setShowStepByStep(!showStepByStep);
    };



    const getVideoIdForArea = (area) => {
        const selectedSmoke = smokeData.find(smoke => smoke.title === area);
        return selectedSmoke ? selectedSmoke.id.split('v=')[1] : '';
    };

    return (
        <section className={`${side === 'counter' ? 'bg-counter-bg' : 'bg-other-bg'}`}>
      <header className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-orange-500">
            <Link to={'/'}>SMOKESCS2</Link>
          </h1>
          <nav>
            <a href="#about" className="text-white mr-4">About</a>
            <a href="#contact" className="text-white">Contact</a>
          </nav>
        </div>
      </header>
      <motion.div
        className="flex flex-col items-center justify-center p-4 h-screen overflow-y-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2 className="text-3xl font-bold text-white mb-12 text-center">Select smoke area for {map}</h2>
        <div className="flex flex-col md:flex-row justify-center w-full items-center">
          {/* Left side: Area selection */}
          <div className="flex flex-col items-center md:mr-8 mb-4 w-full">
            <h3 className="text-lg font-bold text-white mb-2">Select an Area:</h3>
            <div className="flex flex-wrap justify-center gap-4 w-full">
              {availableSmokes.map((smoke, index) => (
                <button
                  key={index}
                  type="button"
                  className={`py-2 px-4 rounded w-full sm:w-auto ${selectedArea === smoke.title ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
                  onClick={() => handleAreaSelect(smoke.title)}
                >
                  {smoke.title}
                </button>
              ))}
            </div>
          </div>
          {/* Right side: Video or Step-by-Step instructions */}
          <div className="flex flex-col items-center w-full">
            {selectedArea && !showStepByStep && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className="mb-4 w-full"
              >
                <iframe
                  className='rounded w-full h-64 sm:h-96 md:w-760 md:h-515'
                  src={`https://www.youtube.com/embed/${getVideoIdForArea(selectedArea)}`}
                  title={`Smoke for ${selectedArea}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </motion.div>
            )}
            {selectedArea && showStepByStep && (
              <div>
                <h3 className="text-lg font-bold mb-2">Step-by-Step for {selectedArea}</h3>
                <div className="flex flex-col md:flex-row items-center justify-center gap-4">
                  {/* Example step images */}
                  <img
                    src={`/step_images/${selectedArea.toLowerCase().replace(' ', '_')}_1.png`}
                    alt={`Step 1 for ${selectedArea}`}
                    className="max-w-xs"
                  />
                  <img
                    src={`/step_images/${selectedArea.toLowerCase().replace(' ', '_')}_2.png`}
                    alt={`Step 2 for ${selectedArea}`}
                    className="max-w-xs"
                  />
                  {/* Add more images as needed */}
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
      <footer className="bg-gray-800 p-4">
        <div className="container mx-auto text-center text-white">
          <p>&copy; 2024 SMOKESCS2. All rights reserved.</p>
          <p>Not affiliated with Valve Corporation.</p>
        </div>
      </footer>
    </section>
    );
};

export default SmokeMap;
