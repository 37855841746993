import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const mapsData = [
  { name: "Dust_II", image: "/dust2.webp" },
  { name: "Mirage", image: "/mirage.webp" },
  { name: "Inferno", image: "/inferno.webp" },
  { name: "Nuke", image: "/nuke.webp" },
  { name: "Vertigo", image: "/vertigo.webp" },
  { name: "Anubis", image: "/anubis.webp" },
  { name: "Ancient", image: "/ancient.webp" },
  { name: "Overpass", image: "/over.webp" }
];

const ChoseMap = ({ onBack, side }) => {
  return (
    <motion.div
      className="absolute inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center p-4 overflow-y-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="text-3xl font-bold text-white mb-4">Choose a map</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 w-full">
        {mapsData.map((map, index) => (
          <Link
            key={index}
            to={`/smoke?map=${encodeURIComponent(map.name)}&side=${side}`}
            className="relative rounded-md overflow-hidden"
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative rounded-md overflow-hidden"
            >
              <img
                src={map.image}
                alt={map.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end justify-center p-4">
                <p className="text-white text-lg font-bold">{map.name}</p>
              </div>
            </motion.div>
          </Link>
        ))}
      </div>
      <button
        onClick={onBack}
        className="mt-6 py-2 px-4 rounded bg-red-500 hover:bg-red-600 text-white"
      >
        Back
      </button>
    </motion.div>
  );
};

export default ChoseMap;
