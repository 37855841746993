import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CompShowInicio from './inicio/inicio.js';
import { useEffect, useState } from 'react';
import ChoseMap from './inicio/choosemap';
import CompSmoke from './inicio/smoke.js';



function App() {



  return (
    <BrowserRouter>
      <div className="App">
        <Routes>

          <Route path='/' element={<CompShowInicio />} />
          <Route path="/choose-map/:side" element={<ChoseMap />} />
          <Route path="/smoke/" element={<CompSmoke />} />


        </Routes>
      </div>
    </BrowserRouter >
  );
}



export default App;
